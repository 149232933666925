import React from 'react';

function Footer() {
  return (
    <footer className="footer-container">
      <p>© {new Date().getFullYear()} Smit. All rights reserved.</p>
    </footer>
  );
}

export default Footer;