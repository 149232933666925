import React from 'react';
import booksData from '../books.json';

function Books() {
  return (
    <div className="reading-list-container-sub">
      <div className="currently-listening">
        <h3>Currently Reading</h3>
        {booksData.currentlyReading.map((book, index) => (
          <p key={index}>
            <a href={book.link}>{book.title}</a> by {book.author}
          </p>
        ))}
      </div>

      <h2>Books I've Read</h2>
      <ul className="book-categories">
        {Object.entries(booksData.completedBooks).map(([category, books]) => (
          <li key={category}>
            {category}
            <ul>
              {books.map((book, index) => (
                <li key={index}>
                  <a href={book.link}>{book.title}</a> by {book.author}
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Books;
