import React from 'react';

function Header() {
  return (
    <header className="header-content">
      <p>
        Hello, welcome to my brain dump.
      </p>
      <p>
        I'm Smit, a software engineer with an ambivert personality. I have a passion for learning new things, 
        whether it's picking up a new language or mastering a skill. In my free time, you'll often find me playing Counter-Strike. Right now, I'm diving into learning French and teaching myself how to play the guitar.
      </p>
      <p>
        This website is my playground to share my thoughts and ideas.
        From the books I read, to the projects I work on, to the music I listen to, this site contains it all.
        I hope you find something useful or inspiring. If you want to have a chat, you can connect with me on{' '}
        <a href="https://www.x.com/itssmitthakkar/">X, the everything app</a>. I'm always up for a good conversation.
      </p>
    </header>
  );
}

export default Header;